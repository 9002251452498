import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import CartState from "./context/CartState";
import LangState from "./context/LangState";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LangState>
      <CartState>
        {" "}
        <App />
      </CartState>
    </LangState>
  </React.StrictMode>
);
