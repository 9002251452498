import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "../src/assets/styles/Global.css";
import Header from "./components/headers/Header";
import Footer from "./components/footer/Footer";
import Testimonials from "./components/testimonials/Testimonials";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import VendorDetails from "./components/vendor/VendorDetails";
import Home from "./components/home_page/Home";
import Services from "./components/services/Services";
import Packages from "./components/packages/Packages";
import Cart from "./components/cart/Cart";
import PackageDetails from "./components/packages/PackageDetails";
import MyAccount from "./components/my_account/MyAccount";
import MyBookings from "./components/my_bookings/MyBookings";
import Help from "./components/help/Help";
import Checkout from "./components/checkout/Checkout";
import ProtectRoute from "./utils/ProtectRoute";
import BookingDetails from "./components/my_bookings/BookingDetails";
import OrderPlaced from "./components/checkout/OrderPlaced";
import Error from "./components/error/Error";
import { Form, InputGroup, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faXmark } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { BASE_URL, clientKey } from "./config/config";
import { useContext, useState } from "react";
import langContext from "./context/langsContext";
import { languageFunc, changedLang } from "./config/language";
import { checkForBlank, validateMobile } from "./lib/validation";

function App() {
  //arabic language-english
  languageFunc(useContext(langContext));

  //mobile number modal state
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //password  modal state
  const [showPass, setShowPass] = useState(false);
  const handleClosePass = () => setShowPass(false);

  //register   modal state
  const [register, setRegister] = useState(false);
  const handleCloseReg = () => setRegister(false);
  const registerShowHandler = () => setRegister(true);
  //loginhandler
  const [mobnum, setMobnum] = useState("");
  const [pwd, setPwd] = useState("");

  //CHECKING MOBILE NUMBER
  const mobileCheckHandler = (e) => {
    e.preventDefault();
    if (!validateMobile("mobnum", "err_mob")) {
      document.getElementById("mobnum").focus();
    } else {
      axios
        .post(`${BASE_URL}/checkmobnum/`, {
          mobnum,
        })
        .then((res) => {
          if (res.data.code === 200) {
            setShow(false);
            setShowPass(true);
            setMobnum(mobnum);
          } else if (res.data.code === 401) {
            setShow(false);
            setShowPass(false);
            setRegister(true);
            setMobnum("");
          } else {
            setShow(true);
            setMobnum("");
          }
        })
        .catch((e) => console.log(e.message));
    }
  };

  //USER LOGIN HANDLER
  const loginHandler = (e) => {
    e.preventDefault();
    if (!checkForBlank("pwd", "err_pwd")) {
      document.getElementById("pwd").focus();
    } else {
      axios
        .post(`${BASE_URL}/checkuser/`, {
          mobnum,
          pwd,
          clientKey,
        })
        .then((res) => {
          if (res.data.code === 200) {
            sessionStorage.setItem("user_id", res.data.userID);
            setShow(false);
            setShowPass(false);
            setPwd("");
            setMobnum("");
          } else {
            setShow(false);
            setShowPass(true);
            setPwd("");
          }
        })
        .catch((e) => console.log(e.message));
    }
  };

  //register
  const [userDetails, setUserDetails] = useState({
    name: "",
    emailid: "",
    mobnum: "",
    pwd: "",
    cpwd: "",
  });

  const changeHandler = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let errors = {};
    // Validation logic
    if (!userDetails.name.trim()) {
      errors.name = "Name is required";
    }
    let emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(userDetails.emailid)) {
      errors.emailid = "Enter a valid email";
    }
    let regex = /(0|91)?[6-9][0-9]{9}/;
    if (!regex.test(userDetails.mobnum)) {
      errors.mobnum = "Enter a valid number";
    }

    if (!userDetails.pwd.trim()) {
      errors.pwd = "Please enter password";
    }
    if (!userDetails.cpwd.trim()) {
      errors.cpwd = "Please confirm password";
    }
    // Add more validation rules as needed
    return errors;
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    const newErrors = validateForm();
    setErrors({ ...errors, [name]: newErrors[name] });
  };

  // register handler
  const registerHandler = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      axios
        .post(`${BASE_URL}/checkregister`, {
          name: userDetails.name,
          emailid: userDetails.emailid,
          mobnum: userDetails.mobnum,
          pwd: userDetails.pwd,
          cpwd: userDetails.cpwd,
        })
        .then((res) => {
          if (res.data.code === 200) {
            setRegister(false);
            setShow(true);
          } else {
            setRegister(true);
          }
        })
        .catch((e) => console.log(e.message));
    } else {
      setErrors(newErrors);
    }
  };
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Header checkAuth={handleShow} />}>
            <Route index element={<Home />} />
            <Route path="myaccount" element={<MyAccount />} />
            <Route
              path="mybooking"
              element={
                <ProtectRoute>
                  <MyBookings />
                </ProtectRoute>
              }
            />
            <Route
              path="booking-details/:id"
              element={
                <ProtectRoute>
                  <BookingDetails />
                </ProtectRoute>
              }
            />
            <Route path="services" element={<Services />} />
            <Route path="packages" element={<Packages />} />
            <Route path="package-details" element={<PackageDetails />} />
            <Route path="cart" element={<Cart checkAuth={handleShow} />} />
            <Route
              path="checkout"
              element={
                <ProtectRoute>
                  <Checkout />
                </ProtectRoute>
              }
            />
            <Route
              path="order-placed"
              element={
                <ProtectRoute>
                  <OrderPlaced />
                </ProtectRoute>
              }
            />
            <Route path="vendor-details" element={<VendorDetails />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="testimonials" element={<Testimonials />} />
            <Route path="help" element={<Help />} />
            <Route path="error" element={<Error />} />
            <Route path="*" element={<Error />} />
          </Route>
        </Routes>
        <Footer />
      </BrowserRouter>

      {/* check mobile number modal */}
      <Modal show={show} onHide={handleClose} centered>
        <div>
          <FontAwesomeIcon
            icon={faXmark}
            className={`${
              sessionStorage.getItem("language") === "ar" ? "ar_cross" : "cross"
            }`}
            onClick={handleClose}
          />
        </div>
        <Modal.Body>
          <Form onSubmit={mobileCheckHandler} className="p-3 needs-validation">
            <Form.Group className="mb-3">
              <Form.Label>
                <h4 className="pb-3 text-color">{changedLang.loginSignup}</h4>
              </Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="countrycode" className="text-secondary">
                  +91 <FontAwesomeIcon icon={faChevronDown} />{" "}
                </InputGroup.Text>
                <Form.Control
                  placeholder={changedLang.enterMobNum}
                  id="mobnum"
                  className="mobnum"
                  type="number"
                  aria-label="Usermob"
                  aria-describedby="basic-addon1"
                  value={mobnum}
                  autoFocus={true}
                  // required
                  name="mobnum"
                  onChange={(e) => {
                    setMobnum(e.target.value);
                  }}
                />
              </InputGroup>
              <div className="err_msg text-danger" id="err_mob">
                Enter valid Mobile Number
              </div>
            </Form.Group>
            <button type="submit" className="btn btn-success w-100">
              {changedLang.proceed}
            </button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* enter password modal */}
      <Modal show={showPass} onHide={handleClosePass} centered>
        <div>
          <FontAwesomeIcon
            icon={faXmark}
            className={`${
              sessionStorage.getItem("language") === "ar" ? "ar_cross" : "cross"
            }`}
            onClick={handleClosePass}
          />
        </div>
        <Modal.Body>
          <Form onSubmit={loginHandler} className="p-3">
            <Form.Group className="mb-3">
              <Form.Label>
                <h4 className="pb-3 text-color">{changedLang.loginSignup}</h4>
              </Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder={changedLang.enterPass}
                  id="pwd"
                  aria-label="Userpass"
                  aria-describedby="basic-addon1"
                  type="password"
                  name="pwd"
                  value={pwd}
                  onChange={(e) => {
                    setPwd(e.target.value);
                  }}
                />
              </InputGroup>
              <div className="err_msg text-danger" id="err_pwd">
                Enter valid Password
              </div>
            </Form.Group>
            <button type="submit" className="btn btn-success w-100">
              {changedLang.login}
            </button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* register modal */}
      <Modal show={register} onHide={registerShowHandler} centered>
        <div>
          <FontAwesomeIcon
            icon={faXmark}
            className={`${
              sessionStorage.getItem("language") === "ar" ? "ar_cross" : "cross"
            }`}
            onClick={handleCloseReg}
          />
        </div>
        <Modal.Body>
          <Form onSubmit={registerHandler} className="p-3 needs-validation">
            <Form.Group>
              <Form.Label>
                <h4 className="pb-3 text-color">{changedLang.register}</h4>
              </Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Name*"
                  id="regname"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  name="name"
                  onChange={changeHandler}
                  onBlur={handleBlur}
                />
              </InputGroup>
              {errors.name && <div className="error">{errors.name}</div>}
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Email*"
                  aria-label="Useremail"
                  type="regemail"
                  id="regemail"
                  aria-describedby="basic-addon1"
                  name="emailid"
                  onChange={changeHandler}
                  onBlur={handleBlur}
                />
              </InputGroup>
              {errors.emailid && <div className="error">{errors.emailid}</div>}
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Mobile*"
                  aria-label="Useremobile"
                  aria-describedby="basic-addon1"
                  type="number"
                  id="regmobile"
                  name="mobnum"
                  onChange={changeHandler}
                  onBlur={handleBlur}
                />
              </InputGroup>
              {errors.mobnum && <div className="error">{errors.mobnum}</div>}
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Password*"
                  aria-label="Userpassword"
                  aria-describedby="basic-addon1"
                  type="password"
                  id="regpwd"
                  name="pwd"
                  onChange={changeHandler}
                  onBlur={handleBlur}
                />
              </InputGroup>
              {errors.pwd && <div className="error">{errors.pwd}</div>}
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Confirm password"
                  aria-label="Usercpassword"
                  aria-describedby="basic-addon1"
                  type="password"
                  id="regcpwd"
                  name="cpwd"
                  onChange={changeHandler}
                  onBlur={handleBlur}
                />
              </InputGroup>
              {errors.cpwd && <div className="error">{errors.cpwd}</div>}
            </Form.Group>
            <button type="submit" className="btn btn-success w-100">
              {changedLang.register}
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default App;
