import React, { useEffect, useState } from "react";
import CartContext from "./cartContext";

const CartState = (props) => {
  // getting cart items form localStorage
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("cartList")) {
      const cartData = JSON.parse(localStorage.getItem("cartList"));
      setCartItems(cartData);
    } else {
      return;
    }
  }, []);

  return (
    <div>
      <CartContext.Provider value={{ cartItems }}>
        {props.children}
      </CartContext.Provider>
    </div>
  );
};

export default CartState;
