import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { BASE_URL, clientKey } from "../../config/config";
import langContext from "../../context/langsContext";
import { languageFunc, changedLang } from "../../config/language";

const Services = () => {
  const navigate = useNavigate();
  //Getting Query String Params
  const [searchParams] = useSearchParams();
  let serviceURL = searchParams.get("param");

  const [services, setServices] = useState([]);
  const [subServices, setSubServices] = useState([]);

  //language id
  let languageID = JSON.parse(sessionStorage.getItem("language"));
  languageID = languageID.id;

  //arabic language-english
  languageFunc(useContext(langContext));

  //Get Sub Services
  const getServices = async () => {
    await axios
      .post(`${BASE_URL}/getsubservices/`, {
        clientKey,
        serviceURL,
        langID: languageID,
      })
      .then((res) => {
        if (res.data.code === 200) {
          setServices(res.data.data);
          setSubServices(res.data.data.subservices);
        } else {
          return;
        }
      })
      .catch((e) => navigate("/error"));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getServices();
    // eslint-disable-next-line
  }, []);
  return (
    <div className="container-fluid">
      <div className="container-fluid service_banner mb-3">
        <div className="text-center bannertext">
          <h1 className="text-white">{services.service_title}</h1>
          <div className="text-white">
            <Link to="/" className="text-white">
              {changedLang.home}
            </Link>
            <span>
              <FontAwesomeIcon icon={faAnglesRight} />
            </span>
            <span className="text-white">{changedLang.service}</span>
          </div>
        </div>
        <div
          className="bg-overlay"
          style={{
            background: "url(" + services.service_banner + ")",
          }}
        ></div>
      </div>
      <h3 className="text-color">{changedLang.selectService}</h3>
      <div className="row mb-4">
        {subServices?.map((data, i) => (
          <Link
            key={i}
            to={`/packages?param=${data.url}`}
            className="col-lg-4 col-md-6 col-sm-12"
          >
            <div key={i}>
              <div className="card mt-3 cards">
                <div className="service-banner card-img">
                  <img src={data.banner} alt="logo" className="card-img-top" />
                </div>
                <div className="card-body ">
                  <h3 className=" text-color">{data.title}</h3>
                  <p className="card-text ">{data.description}</p>
                  <Link to={`/packages?param=${data.url}`}>
                    {changedLang.getDetails}
                  </Link>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Services;
