import {
  faAnglesRight,
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BASE_URL, clientKey } from "../../config/config";
import axios from "axios";
import langContext from "../../context/langsContext";
import { languageFunc, changedLang } from "../../config/language";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // language english - arabic
  languageFunc(useContext(langContext));

  // contact us form state variables
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [subject, setSubject] = useState("");
  const [requirement, setRequirement] = useState("");

  //error handling
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let errors = {};
    // Validation logic
    if (!name) {
      errors.name = "Name is required";
    }
    let emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      errors.email = "Enter a valid email";
    }
    let regex = /(0|91)?[6-9][0-9]{9}/;
    if (!regex.test(mobile)) {
      errors.mobile = "Enter a valid number";
    }
    if (!subject) {
      errors.subject = "Subject is required";
    }
    if (!requirement) {
      errors.requirement = "Requirement is required";
    }
    // Add more validation rules as needed
    return errors;
  };
  const handleBlur = (e) => {
    const { name } = e.target;
    const newErrors = validateForm();
    setErrors({ ...errors, [name]: newErrors[name] });
  };

  const [success, setSuccess] = useState(false);
  //submit handler
  const submitHandler = (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      axios
        .post(`${BASE_URL}/saveenquiry`, {
          clientKey,
          name,
          email,
          mobile,
          subject,
          requirement,
        })
        .then((res) => {
          if (res.data.code === 200) {
            setSuccess(true);
            setName("");
            setEmail("");
            setMobile("");
            setSubject("");
            setRequirement("");
          } else {
            console.log(res.data.code);
          }
        })
        .catch((e) => console.log(e.message));
    } else {
      setErrors(newErrors);
    }
    setTimeout(() => {
      setSuccess(false);
    }, 1500);
  };
  return (
    <div>
      <div>
        <div className="container-fluid bannerhead">
          <div className="text-center">
            <h1 className="text-white">{changedLang.contact}</h1>
            <div className="text-white">
              <Link to="/" className="text-white">
                {changedLang.home}
              </Link>{" "}
              <span>
                <FontAwesomeIcon icon={faAnglesRight} />
              </span>{" "}
              <span className="text-white">{changedLang.contact}</span>
            </div>
          </div>
        </div>
      </div>
      <h2 className=" mx-5 p-5 text-color">{changedLang.getInTouch}</h2>
      {success && (
        <div class="alert alert-success w-50 m-auto" role="alert">
          Congratulations!, Submitted Successfully...!
        </div>
      )}
      <div className="container contact_form">
        <Form className="row" onSubmit={submitHandler}>
          <div className="col-lg-6">
            <Form.Group className="mb-4">
              <input
                type="name"
                placeholder="Name"
                className="form-control"
                value={name}
                name="name"
                onChange={(e) => setName(e.target.value)}
                onBlur={handleBlur}
              />
            </Form.Group>
            {errors.name && <div className="error">{errors.name}</div>}
          </div>
          <div className="col-lg-6">
            <Form.Group className="mb-4">
              <input
                type="email"
                placeholder="Email"
                className="form-control"
                value={email}
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                onBlur={handleBlur}
              />
            </Form.Group>
            {errors.email && <div className="error">{errors.email}</div>}
          </div>
          <div className="col-lg-6">
            <Form.Group className="mb-4">
              <input
                type="number"
                placeholder="Phone"
                className="form-control"
                value={mobile}
                name="mobile"
                onChange={(e) => setMobile(e.target.value)}
                onBlur={handleBlur}
              />
            </Form.Group>
            {errors.mobile && <div className="error">{errors.mobile}</div>}
          </div>
          <div className="col-lg-6">
            <Form.Group className="mb-4">
              <input
                type="text"
                placeholder="Subject"
                className="form-control"
                value={subject}
                name="subject"
                onChange={(e) => setSubject(e.target.value)}
                onBlur={handleBlur}
              />
            </Form.Group>
            {errors.subject && <div className="error">{errors.subject}</div>}
          </div>
          <div className="col-lg-6">
            <Form.Group className="mb-4">
              <textarea
                className="form-control"
                type="text"
                placeholder="Your Requirements"
                value={requirement}
                name="requirement"
                onChange={(e) => setRequirement(e.target.value)}
                onBlur={handleBlur}
              />
            </Form.Group>
            {errors.requirement && (
              <div className="error">{errors.requirement}</div>
            )}
          </div>

          <div className="success"></div>
          <div className="row">
            <div className="col-6 mb-5">
              <button
                variant="primary"
                type="submit"
                className="w-50 btn btn-primary rounded-corner"
              >
                {changedLang.submit}
              </button>
            </div>
            <div id="err" className="text-danger"></div>
          </div>
        </Form>
      </div>
      <h2 className="text-center pb-5 text-color">{changedLang.contInfo}</h2>
      <div className="container bg-light p-3 mb-3">
        <div className="row">
          <div className="col-lg-4 info">
            <div className="icons">
              <FontAwesomeIcon icon={faLocationDot} className="icon" />
            </div>
            <div className="para">
              <p>ADDRESS INFO</p>
              <p>Building No:3456, Burje Kalifa, Riyadh, 12345, Saudi Arabia</p>
            </div>
          </div>
          <div className="col-lg-4 info">
            <div className="icons">
              <FontAwesomeIcon icon={faPhone} className="icon" />
            </div>
            <div className="para">
              <p>PHONE NUMBER</p>
              <p>Tel - +966 11 291 3124</p>
              <p>Mob - +966 561 931 153</p>
            </div>
          </div>
          <div className="col-lg-4 info">
            <div className="icons">
              <FontAwesomeIcon icon={faEnvelope} className="icon" />
            </div>
            <div className="para">
              <p>EMAIL ADDRESS</p>
              <p>info@unjiz.com</p>
              <p>www.unjiz-service.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
