import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { BASE_URL, clientKey, currency } from "../../config/config";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import BreadCrumbs from "../../utils/BreadCrumbs";
import langContext from "../../context/langsContext";
import { languageFunc, changedLang } from "../../config/language";

const VendorDetails = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let packageURL = searchParams.get("param");
  //get packages
  const [vendorDetails, setVendorDetails] = useState([]);
  const [packageDetails, setPackageDetails] = useState([]);
  const [reviwCounts, setReviewCounts] = useState([]);

  //laguage id
  let languageID = JSON.parse(sessionStorage.getItem("language"));
  languageID = languageID.id;

  //arabic language-english
  languageFunc(useContext(langContext));

  //getting vendors details
  const getVendorDetails = async () => {
    axios
      .post(`${BASE_URL}/getvendordetails/`, {
        clientKey,
        packageURL,
        langID: languageID,
      })
      .then((res) => {
        if (res.data.code === 200) {
          setVendorDetails(res.data.data);
          setPackageDetails(res.data.data.packages);
          setReviewCounts(res.data.data.review_count);
        } else {
          return;
        }
      })
      .catch((e) => navigate("/error"));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getVendorDetails();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container">
      <div className="allpages">
        <BreadCrumbs />
        <div className="vendor_details">
          <div className="vendor_img">
            <img src={vendorDetails.logo} alt="vendor" />
          </div>
          <div className="vendor_review">
            <p className="vendor_star">
              <span> {changedLang.review}:</span>
              <span>
                <i data-star={reviwCounts.review_stars}></i>
              </span>
            </p>
            <p>
              {changedLang.totalReview}: {reviwCounts.total_reviews}
            </p>
          </div>
        </div>
        <h3 className=" text-color mb-3">
          {vendorDetails.company} {changedLang.services}
        </h3>
        <div className="row vendor_package">
          {packageDetails?.map((data, i) => (
            <>
              <Link
                to={`/package-details?param=${data.url}`}
                className="col-lg-6 col-md-6 col-sm-6 mb-3"
                key={i}
              >
                <div className="cards p-3">
                  <div>
                    <h4 className="text-color">{data.title}</h4>
                    <p>{data.desc}</p>
                    {data.cost !== 0 && (
                      <h6 className=" text-color">
                        {currency} {data.cost}{" "}
                        <span className="small-text">/Package</span>
                      </h6>
                    )}
                    {data.hourly_cost !== 0 && (
                      <h6 className=" text-color">
                        {currency} {data.hourly_cost}
                        <span className="small-text">/Hr</span>
                      </h6>
                    )}

                    <Link to={`/package-details?param=${data.url}`}>
                      {changedLang.getDetails}
                    </Link>
                  </div>
                  <div className="vendor_pkg_img">
                    <img
                      src={data.banner}
                      alt="logo"
                      className="card-img-top"
                    />
                  </div>
                </div>
              </Link>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VendorDetails;
