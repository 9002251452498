import {
  faEnvelope,
  faLocationDot,
  faMobile,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL, clientKey } from "../../config/config";
import langContext from "../../context/langsContext";
import { languageFunc, changedLang } from "../../config/language";
import logo from "../../assets/images/logo.jpg";
const Footer = () => {
  const navigate = useNavigate();
  //arabic language-english
  languageFunc(useContext(langContext));

  let languageID = JSON.parse(sessionStorage.getItem("language"));
  languageID = languageID.id;

  //get services
  const [services, setServices] = useState([]);
  const getServices = async () => {
    axios
      .post(`${BASE_URL}/getservices/`, {
        clientKey,
        langID: languageID,
      })
      .then((res) => {
        if (res.data.code === 200) {
          setServices(res.data.data);
        } else {
          return;
        }
      })
      .catch(() => navigate("/error"));
  };
  useEffect(() => {
    getServices();
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <footer className="footer text-lg-start">
        <div className="container p-4 text-white">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-4 ">
              <img src={logo} alt="banner" height={200} width={200} />
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-4 ">
              <h5 className="footer-topics mb-4">{changedLang.usefullLinks}</h5>

              <ul className="footer-link">
                <li>
                  <Link to="/" className="text-white">
                    {changedLang.home}
                  </Link>
                </li>

                <li>
                  <Link to="about" className="text-white">
                    {changedLang.about}
                  </Link>
                </li>
                <li>
                  <Link to="contact" className="text-white">
                    {changedLang.contact}
                  </Link>
                </li>
                <li>
                  <Link to="/" className="text-white">
                    {changedLang.termsCond}
                  </Link>
                </li>
                <li>
                  <Link to="help" className="text-white">
                    {changedLang.help}
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-4 ">
              <h5 className="footer-topics mb-4">{changedLang.services}</h5>

              <ul className="list-unstyled mb-0">
                {services &&
                  services.map((service, i) => (
                    <li key={i}>
                      <a
                        href={`services?param=${service.url}`}
                        className="text-white"
                      >
                        {service.title}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 ">
              <h5 className="footer-topics mb-4">{changedLang.getInTouch}</h5>
              <ul className="list-unstyled">
                <li className="d-flex gap-3">
                  <FontAwesomeIcon icon={faLocationDot} />
                  <p>
                    Building No:3456, Burje Kalifa, Riyadh, 12345, Saudi Arabia
                  </p>
                </li>
                <li className="d-flex gap-3">
                  <FontAwesomeIcon icon={faPhone} />
                  <p>+966 1 123 1234</p>
                </li>
                <li className="d-flex gap-3">
                  <FontAwesomeIcon icon={faMobile} />
                  <p>+966 1 123 1234</p>
                </li>
                <li className="d-flex gap-3">
                  <FontAwesomeIcon icon={faEnvelope} />
                  <p>info@unjiz.com</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="p-3 text-white text-center footer-bottom">
          © 2024 {changedLang.copyRight} :{" "}
          <Link to="/" className="text-white">
            UNJIZ
          </Link>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
