import LangContext from "./langsContext";

const LangState = (props) => {
  const engLang = {
    // Header
    myAcc: "My Account",
    myBooking: "My Booking",
    logOut: "Logout",
    login: "Login",
    loginSignup: "Login/Sign up",
    proceed: "Proceed",
    register: "Register",
    enterMobNum: "Enter Mobile Number",
    enterPass: "Enter Password",
    name: "Your Name",
    email: "Your Email",
    yourMobile: "Your Mobile",
    password: "Your Password",
    cPassword: "Confirm Password",

    //main page
    aboutPage: "About Page",
    aboutPageSubhead: "Greetings from Unjiz Cleaning Company",
    service: "Our Services",
    serviceSubhead: "Your trusted services",
    testimonial: "Client Testimonials",
    testimonialSubHead: "Unjiz Cleaning Service",
    location: "Bangalore",
    readMore: "Read more",
    // footer
    getInTouch: "Get in Touch",
    usefullLinks: "Usefull links",
    reqCall: "Request a Callback",
    home: "Home",
    about: "About us",
    contact: "Contact us",
    termsCond: "Terms & Conditions",
    copyRight: "Copyright",
    // about page
    whyChoose: "Why to choose us ?",
    ansWhyChoose: "We will make any place clean and tidy.",
    trackRecord: "Track Record",
    knowledge: "Knowledge",
    exp: "Experience",
    flex: "Flexibility",
    // contact us
    contInfo: "Conatct info",
    submit: "Submit",
    //cart
    cart: "Cart",
    title: "Title",
    amnt: "Amount",
    totalCost: "Total cost",
    noItems: "No items in your cart",
    // checkout
    add1: "Address1",
    add2: "Address2",
    city: "City",
    loc: "Location",
    zip: "Zipcode",
    serviceAdd: "Service Address",
    contName: "Contact Name",
    mobile: "Mobile",
    serviceDate: "Service Date",
    serviceTime: "Service Time",
    remarks: "Remarks",
    paymentSummary: "Payment Summary",
    subTotalCost: "Subtotal cost",
    tax: "Tax (Vat 0%)",
    totalAmountToPay: "Total Amount to pay",
    proceedToPay: "Proceed to Pay",
    checkoutBtn: "Checkout",
    // subservices
    selectService: "Select our services",
    getDetails: "Get Details",
    // package/packages getDetails
    packages: "Packages",
    packageDetails: "Packages-Details",
    add: "Add",
    added: "Added",
    viewCart: "View Cart",
    justAt: "Just at",

    //vendors
    review: "Reviews",
    totalReview: "Total Reviews",
    services: "Services",
    subServices: "Sub Services",
    vendorDetails: "Vendor Details",
    // my bookings and booking getDetails
    bookingHead: "Bookings and Plans",
    status: "Status",
    bookingRef: "Booking Ref",
    bookingDate: "Booking date",
    view: "View",
    amountPaid: "Amount paid",
    booking_details: "Booking Details",
    refNo: "Ref No",
    date: "Date",
    packageTitle: "Package Title",
    amount: "Amount",
    totalVat: "Total Vat",
    // my profile
    changeEmail: "Change Email",
    changeMobile: "Change Mobile Number",
    changePassword: "Change Password",
    help: "Help",
  };
  const arabicLang = {
    // Header
    myAcc: "حسابي",
    myBooking: "الحجز",
    logOut: "تسجيل خروج",
    login: "تسجيل الدخول",
    loginSignup: "الدخول التسجيل فى الموقع",
    proceed: "يتابع",
    register: "يسجل",
    enterMobNum: "أدخل رقم هاتفك المحمول",
    enterPass: " ادخل رقمك السري",
    name: "اسمك",
    email: "بريدك الالكتروني",
    yourMobile: "هاتفك النقال",
    password: "كلمة السر خاصتك",
    cPassword: "تأكيد كلمة المرور",
    //main page
    aboutPage: "حول الصفحة",
    aboutPageSubhead: "مع تحيات شركة إنجاز للتنظيف",
    service: "صفحة الخدمة",
    serviceSubhead: "خدمة التنظيف الموثوقة لديك",
    testimonial: "شهادات العميل",
    testimonialSubHead: "خدمة تنظيف انجز",
    location: "بنغالور",
    readMore: "اقرأ أكثر",
    // footer
    getInTouch: "ابقى على تواصل",
    usefullLinks: "روابط مفيدة",
    reqCall: "طلب استدعاء",
    home: "بيت",
    about: "معلومات عنا",
    contact: "اتصل بنا",
    termsCond: "البنود و الظروف",
    copyRight: "حقوق النشر ",
    //about page
    whyChoose: "لماذا تختارنا؟",
    ansWhyChoose: "سنجعل أي مكان نظيفًا ومرتبًا.",
    trackRecord: "رقم قياسي",
    knowledge: "معرفة",
    exp: "خبرة",
    flex: "المرونة",
    // contact us
    contInfo: "معلومات الاتصال",
    submit: "يُقدِّم",
    // cart
    cart: "عربة التسوق",
    title: "عنوان",
    amnt: "كمية ",
    totalCost: "التكلفة الإجمالية",
    noItems: "لا توجد عناصر في سلة التسوق الخاصة بك",
    // checkout
    add1: "العنوان 1",
    add2: "العنوان 2",
    city: "مدينة",
    loc: "موقع",
    zip: "الرمز البريدي",
    serviceAdd: "عنوان الخدمة",
    contName: "اسم جهة الاتصال",
    mobile: "متحرك",
    serviceDate: "تاريخ الخدمة",
    serviceTime: "وقت الخدمة",
    remarks: "ملاحظات",
    paymentSummary: "ملخص الدفع",
    subTotalCost: "التكلفة الإجمالية الفرعية",
    tax: "ضريبة(0%)",
    totalAmountToPay: "المبلغ الإجمالي للدفع",
    proceedToPay: "المضي قدما في الدفع",
    checkoutBtn: "الدفع",
    // subservices
    selectService: "اختر خدماتنا",
    getDetails: "احصل على التفاصيل",
    // package/packages getDetails
    packages: "الحزم",
    packageDetails: "تفاصيل الحزم",
    add: "يضيف",
    added: "تمت الإضافة",
    viewCart: "عرض العربة",
    justAt: "فقط في",
    //vendors
    subServices: "الخدمات الفرعية",
    vendorDetails: "تفاصيل البائع",
    review: "التعليقات",
    totalReview: "إجمالي المراجعات",
    services: "خدمات",
    // my bookings and booking getDetails
    bookingHead: "الحجوزات والخطط",
    status: "حالة",
    bookingRef: "مرجع الحجز",
    bookingDate: "تاريخ الحجز",
    view: "منظر",
    amountPaid: "المبلغ المدفوع",
    booking_details: "تفاصيل الحجز",
    refNo: "مصدر رقم",
    date: "تاريخ",
    packageTitle: "عنوان الحزمة",
    amount: "كمية",
    totalVat: "إجمالي ضريبة القيمة المضافة",
    // my profile
    changeEmail: "تغيير الايميل",
    changeMobile: "تغيير رقم الجوال",
    changePassword: "تغيير كلمة المرور",
    help: "يساعد",
  };

  return (
    <LangContext.Provider value={{ engLang, arabicLang }}>
      {props.children}
    </LangContext.Provider>
  );
};
export default LangState;
