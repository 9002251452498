import React, { useContext } from "react";
import { Breadcrumb } from "react-bootstrap";
import langContext from "../context/langsContext";
import { languageFunc, changedLang } from "../config/language";

const BreadCrumbs = () => {
  //arabic language-english
  const langs = useContext(langContext);
  languageFunc(langs);

  const subServicesClickHandler = () => {
    window.history.go(-3);
  };
  const packageClickHandler = () => {
    window.history.go(-2);
  };
  const packageDetailsHandler = () => {
    window.history.go(-1);
  };
  return (
    <Breadcrumb>
      <Breadcrumb.Item href="/">{changedLang.services}</Breadcrumb.Item>
      <Breadcrumb.Item onClick={subServicesClickHandler}>
        {changedLang.subServices}
      </Breadcrumb.Item>
      <Breadcrumb.Item onClick={packageClickHandler}>
        {" "}
        {changedLang.packages}
      </Breadcrumb.Item>
      <Breadcrumb.Item onClick={packageDetailsHandler}>
        {changedLang.packageDetails}
      </Breadcrumb.Item>
      <Breadcrumb.Item active> {changedLang.vendorDetails}</Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default BreadCrumbs;
