import { faCircleUser, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import cartContext from "../../context/cartContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import langContext from "../../context/langsContext";
import { BASE_URL, clientKey, currency } from "../../config/config";
import { languageFunc, changedLang } from "../../config/language";

const Checkout = () => {
  const cart = useContext(cartContext);
  let cartData = cart?.cartItems;
  const navigate = useNavigate();
  // language english - arabic
  languageFunc(useContext(langContext));

  // customer details state
  const [customerDetails, setCustomerDetails] = useState([]);

  //customer address state
  const [serviceAddress, setServiceAddress] = useState([]);
  const [address, setAddress] = useState([]);
  const [cities, setCities] = useState([]);
  //calculation total cost to pay
  let totalCost = 0;
  cartData.map((data) => (totalCost += data.package_cost));
  let vat = (totalCost * 0) / 100;
  let subTotal = totalCost + vat;

  //address fields state
  const [title, setTile] = useState("");
  const [cityID, setCityID] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [location, setLocation] = useState("");
  const [zipCode, setZipCode] = useState("");

  //checkout state
  const [serviceDate, setServiceDate] = useState("");
  const [serviceTime, setserviceTime] = useState("");
  const [remarks, setRemarks] = useState("");

  //dropdown select state
  const [selectedOption, setSelectedOption] = useState("");
  //laguage id
  let languageID = JSON.parse(sessionStorage.getItem("language"));
  languageID = languageID.id;

  //getting customer details
  const getCutomerDetails = () => {
    axios
      .post(`${BASE_URL}/getcustomerbyid`, {
        clientKey,
        userID: sessionStorage.getItem("user_id"),
        langID: languageID,
      })
      .then((res) => {
        if (res.data.code === 200) {
          setCustomerDetails(res.data.data);
        } else {
          navigate("/error");
        }
      })
      .catch((e) => navigate("/error"));
  };
  //get cities
  const getCities = () => {
    axios
      .post(`${BASE_URL}/getcities`, {
        clientKey,
        langID: languageID,
      })
      .then((res) => {
        if (res.data.code === 200) {
          setCities(res.data.data);
        } else {
          return;
        }
      })
      .catch((e) => navigate("/error"));
  };
  //getting  all addresses
  const getAllAdress = () => {
    axios
      .post(`${BASE_URL}/getalladdress`, {
        clientKey,
        userID: sessionStorage.getItem("user_id"),
        langID: languageID,
      })
      .then((res) => {
        if (res.data.code === 200) {
          setServiceAddress(res.data.data);
        } else {
          return;
        }
      })
      .catch((e) => navigate("/error"));
  };

  useEffect(() => {
    getCutomerDetails();
    getAllAdress();
    getCities();
    // eslint-disable-next-line
  }, []);

  //dropdown change handler
  const handleDropdownChange = (e) => {
    if (e.target.value > 0) {
      //Get Address
      axios
        .post(`${BASE_URL}/getaddressdetails`, {
          clientKey,
          addressID: e.target.value,
        })
        .then((res) => {
          if (res.data.code === 200) {
            setAddress(res.data.data);
          }
        });
    }
    setSelectedOption(e.target.value);
  };
  //order place handler
  const orderPlacedClickHandler = (e) => {
    e.preventDefault();
    // new address form validation
    if (!selectedOption) {
      document.getElementById("err_address").innerHTML =
        "Please select  address";
      document.getElementById("DdlAddress").focus();
      return;
    } else {
      document.getElementById("err_address").innerHTML = "";
    }

    if (selectedOption < 0) {
      if (!title) {
        document.getElementById("err_title").innerHTML =
          "Please enter the title";
        document.getElementById("validateTitle").focus();
        return;
      } else {
        document.getElementById("err_title").innerHTML = "";
      }
      if (!address1) {
        document.getElementById("err_add1").innerHTML = "Please enter address1";
        document.getElementById("validateAddress1").focus();
        return;
      } else {
        document.getElementById("err_add1").innerHTML = "";
      }
      if (!cityID) {
        document.getElementById("err_city").innerHTML = "Please select city";
        document.getElementById("validateCity").focus();
        return;
      } else {
        document.getElementById("err_city").innerHTML = "";
      }
      if (!location) {
        document.getElementById("err_location").innerHTML =
          "Please enter your location";
        document.getElementById("location").focus();
        return;
      } else {
        document.getElementById("err_location").innerHTML = "";
      }
      if (!zipCode) {
        document.getElementById("err_zipcode").innerHTML =
          "Please enter zipcode";
        document.getElementById("validateZipcode").focus();
        return;
      } else {
        document.getElementById("err_zipcode").innerHTML = "";
      }
    }
    if (!serviceDate) {
      document.getElementById("err_service_date").innerHTML =
        "Please select  service date";
      document.getElementById("serviceDate").focus();
      return;
    } else {
      document.getElementById("err_service_date").innerHTML = "";
    }
    if (!serviceTime) {
      document.getElementById("err_service_time").innerHTML =
        "Please select  service time";
      document.getElementById("serviceTime").focus();
      return;
    } else {
      document.getElementById("err_service_time").innerHTML = "";
    }
    if (localStorage.getItem("cartList")) {
      axios
        .post(`${BASE_URL}/savebookings`, {
          clientKey,
          cartData,
          userID: sessionStorage.getItem("user_id"),
          addressID: selectedOption,
          title,
          address1,
          address2,
          cityID,
          location,
          zipCode,
          serviceDate,
          serviceTime,
          remarks,
        })
        .then((res) => {
          if (res.data.code === 200) {
            localStorage.removeItem("cartList");
            navigate("/order-placed");
          } else {
            console.log(res.data.code);
          }
        })
        .catch((e) => console.log(e.message));
    }
    navigate("/error");
  };

  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  // date function to handle calendar
  function addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }
  const date = new Date();
  let day = addZero(date.getDate() + 1);
  let month = addZero(date.getMonth() + 1);
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  return (
    <div className="container allpages">
      <form className="checkout p-3 " onSubmit={orderPlacedClickHandler}>
        <div className=" card checkout_box1 p-3">
          <div className=" row p-3 ">
            <div className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
              <FontAwesomeIcon icon={faCircleUser} className="user_icon" />
            </div>
            <div className="col-8 col-xl-8 col-lg-8 col-md-8 col-sm-8">
              <p>{customerDetails.customer_name}</p>
              <p>
                <FontAwesomeIcon icon={faPhone} className="text-color" />{" "}
                {customerDetails.customer_mobile}
              </p>
            </div>
          </div>
          <hr />
          <div>
            <h5 className="text-color">{changedLang.serviceAdd}</h5>
            {/* select address for booking */}
            <select
              className="btn btn-light address_dropdown"
              value={selectedOption}
              onChange={handleDropdownChange}
              id="DdlAddress"
            >
              <option value="0">Select Address</option>
              {serviceAddress?.map((add, i) => (
                <option key={i} value={add.address_id}>
                  {add.address_title}
                </option>
              ))}
              <option value="-1">Add new Address</option>
            </select>
            <div className="err-msg" id="err_address"></div>
            {/* displaying old addres details */}
            <div
              className={`${selectedOption >= "0" ? "showForm" : "hideform"}`}
            >
              {address?.map((add, i) => (
                <div key={i} className="mt-4">
                  <h6>{add?.address_title} :</h6>
                  <div>
                    {add?.address_1} {add?.address_2} {add?.city_name}{" "}
                  </div>
                  <div>
                    {add?.location} {add?.zip_code}{" "}
                  </div>
                </div>
              ))}
            </div>
            {/* adding new address */}
            <div
              className={`${selectedOption === "-1" ? "showForm" : "hideform"}`}
            >
              <div className="row mt-3 g-3 ">
                <div className="col-md-3 col-sm-3">
                  <label htmlFor="validateTitle" className="form-label">
                    {changedLang.title}
                    <span className="text-danger">*</span>
                  </label>
                </div>
                <div className="col-md-9 col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    id="validateTitle"
                    name="title"
                    value={title}
                    onChange={(e) => setTile(e.target.value)}
                  />
                  <div className="err-msg" id="err_title"></div>
                </div>
                <div className="col-md-3 col-sm-3">
                  <label htmlFor="validateAddress1" className="form-label">
                    {changedLang.add1}
                    <span className="text-danger">*</span>
                  </label>
                </div>
                <div className="col-md-9 col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    id="validateAddress1"
                    name="address1"
                    value={address1}
                    onChange={(e) => setAddress1(e.target.value)}
                  />
                  <div className="err-msg" id="err_add1"></div>
                </div>
                <div className="col-md-3 col-sm-3">
                  <label htmlFor="validateAddress2" className="form-label">
                    {changedLang.add2}
                  </label>
                </div>
                <div className="col-md-9 col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    id="validateAddress2"
                    name="address2"
                    value={address2}
                    onChange={(e) => setAddress2(e.target.value)}
                  />
                </div>
                <div className="col-md-3 col-sm-3">
                  <label htmlFor="validateCity" className="form-label">
                    {changedLang.city}
                    <span className="text-danger">*</span>
                  </label>
                </div>
                <div className="col-md-9 col-sm-9">
                  <select
                    className="form-select"
                    id="validateCity"
                    value={cityID}
                    onChange={(e) => setCityID(e.target.value)}
                  >
                    <option>Select City</option>
                    {cities.map((city, i) => (
                      <option
                        key={i}
                        value={city.city_id}
                        label={city.city_name}
                      ></option>
                    ))}
                  </select>
                  <div className="err-msg" id="err_city"></div>
                </div>
                <div className="col-md-3 col-sm-3">
                  <label htmlFor="location" className="form-label">
                    {changedLang.loc}
                    <span className="text-danger">*</span>
                  </label>
                </div>
                <div className="col-md-9 col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    id="location"
                    name="location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                  <div className="err-msg" id="err_location"></div>
                </div>
                <div className="col-md-3 col-sm-3">
                  <label htmlFor="validateZipcode" className="form-label">
                    {changedLang.zip}
                    <span className="text-danger">*</span>
                  </label>
                </div>
                <div className="col-md-9 col-sm-9">
                  <input
                    type="number"
                    className="form-control"
                    id="validateZipcode"
                    name="zipcode"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                  />
                  <div className="err-msg" id="err_zipcode"></div>
                </div>
              </div>
            </div>
            {/* contact details and service date-time */}
            <div className="mt-3 p-3">
              <div className="serive_timing">
                <p>{changedLang.contName}:</p>
                <p>{customerDetails.customer_name}</p>
              </div>
              <div className="serive_timing">
                <p>{changedLang.mobile}:</p>
                <p>{customerDetails.customer_mobile}</p>
              </div>
              <div className="serive_timing">
                <p>
                  {changedLang.serviceDate}{" "}
                  <span className="text-danger">*</span>
                </p>
                <p>
                  <input
                    type="date"
                    id="serviceDate"
                    className="form-control"
                    min={currentDate}
                    value={serviceDate}
                    onChange={(e) => setServiceDate(e.target.value)}
                  />
                  <div className="err-msg" id="err_service_date"></div>
                </p>
              </div>
              <div className="serive_timing">
                <p>
                  {changedLang.serviceTime}{" "}
                  <span className="text-danger">*</span>
                </p>
                <p>
                  <input
                    type="time"
                    id="serviceTime"
                    className="form-control"
                    value={serviceTime}
                    onChange={(e) => setserviceTime(e.target.value)}
                  />
                  <div className="err-msg" id="err_service_time"></div>
                </p>
              </div>
              <div>
                <p>{changedLang.remarks}:</p>
                <p>
                  <textarea
                    type="text"
                    className="form-control"
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                  ></textarea>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* cartdata and payment summary */}
        <div className=" card checkout_box2 p-3">
          <h3 className="text-color">{changedLang.cart}</h3>
          <div className=" checkout_cart">
            {localStorage.getItem("cartList") &&
              cartData?.map((data, i) => (
                <div key={i} className="row p-1">
                  <div className="col-12 col-xl-12 col-lg-12 col-md-10 col-sm-10 bg-light p-1">
                    <div className="row cart_item_price">
                      <div className="col-8 col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8">
                        {data.package_title}
                        <span className="small-text">
                          {data.cost_type === "1" ? "/Package" : "/Hr"}
                        </span>
                      </div>
                      <div className="col-4 col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                        {currency} {data.package_cost}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="w-100 card p-3 bg-light">
            <h6 className="text-color"> {changedLang.paymentSummary}</h6>
            <div className="mb-2 d-flex justify-content-between">
              <div className="">{changedLang.subTotalCost}</div>
              <div className="">
                {currency} {totalCost}
              </div>
            </div>
            <div className="mb-2 d-flex justify-content-between">
              <div className="">{changedLang.tax}</div>
              <div className="">
                {currency} {vat}
              </div>
            </div>
            <div className="mb-2 d-flex justify-content-between">
              <h6 className="text-color">{changedLang?.totalAmountToPay}</h6>
              <div className="">
                {currency} {subTotal}
              </div>
            </div>
          </div>
          <div className="col-12 p-3 ">
            <button className="btn  btn-success w-100">
              {changedLang?.proceedToPay}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Checkout;
