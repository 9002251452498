import React, { useContext, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCartShopping,
  faChevronDown,
  faFolderClosed,
  faLocationDot,
  faRightToBracket,
  faSearch,
  faUser,
  faUserAlt,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "./Header.css";
import globe from "../../assets/images/Globe_Atlantic.svg.png";
import usa from "../../assets/images/us.jpeg";
import logo from "../../assets/images/logo.jpg";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { BASE_URL, clientKey } from "../../config/config";
import cartContext from "../../context/cartContext";
import langContext from "../../context/langsContext";
import { Form, Modal } from "react-bootstrap";
import { languageFunc, changedLang } from "../../config/language";

const Header = (props) => {
  const navigate = useNavigate();

  //search modal state
  const [showSearch, setShowSearch] = useState(false);
  const handleCloseSearch = () => setShowSearch(false);
  const handleShowSearch = () => setShowSearch(true);

  const [locationOptions, setLocationOptions] = useState([]);

  //get services and filtering
  const [searchDetails, setSearchDetails] = useState([]);
  const [search, setSearch] = useState("");

  //getting lang id
  let languageID;
  if (sessionStorage.getItem("language")) {
    languageID = JSON.parse(sessionStorage.getItem("language"));
    languageID = languageID.id;
  } else {
    sessionStorage.setItem(
      "language",
      JSON.stringify({ id: "1", language: "EN", flag: "en", flagImage: usa })
    );
  }

  //arabic language-english
  languageFunc(useContext(langContext));

  //getting services
  const getSearchServices = async (data) => {
    setSearch(data);
    if (data !== "") {
      axios
        .post(`${BASE_URL}/searchpage/`, {
          clientKey,
          langID: languageID,
          searchKey: data,
        })
        .then((res) => {
          if (res.data.code === 200) {
            setSearchDetails(res.data.data);
          } else {
            setSearchDetails("");
          }
        })
        .catch(() => navigate("/error"));
    } else {
      setSearchDetails("");
    }
  };

  const clickedSearchedHandler = (data) => {
    let url = "";
    if (data.type === 1) {
      //Service
      url = "services?param=" + data.pretty_url;
    } else if (data.type === 2) {
      //Sub Service
      url = "packages?param=" + data.pretty_url;
    } else if (data.type === 3) {
      //Packages
      url = "/package-details?param=" + data.pretty_url;
    }
    setMdShow(false);
    navigate(url);
    window.location.reload();
  };
  //get cities
  const getCities = () => {
    axios
      .post(`${BASE_URL}/getcities`, {
        clientKey,
        langID: languageID,
      })
      .then((res) => {
        if (res.data.code === 200) {
          setLocationOptions(res.data.data);
        } else {
          navigate("/error");
        }
      })
      .catch(() => navigate("/error"));
  };

  const [languageOptions, setLanguageOptions] = useState([]);
  //get cities
  const getLanguages = () => {
    axios
      .post(`${BASE_URL}/getlanguages`, {
        clientKey,
        langID: languageID,
      })
      .then((res) => {
        if (res.data.code === 200) {
          setLanguageOptions(res.data.data);
        } else {
          navigate("/error");
        }
      })
      .catch((e) => console.log(e.message));
  };

  useEffect(() => {
    getCities();
    getLanguages();
    // eslint-disable-next-line
  }, []);

  //select location
  const [locations, setLocations] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(
    sessionStorage.getItem("location")
      ? JSON.parse(sessionStorage.getItem("location"))
      : { city_id: 1, city_name: "Riyadh" }
  );

  const selectLocationOptionHandler = (option) => {
    sessionStorage.setItem("location", JSON.stringify(option));
    setSelectedLocation(option);
    setLocations(false);
    setShowOptions(false);
    setMdShow(false);
  };

  //select language
  const [showOptions, setShowOptions] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    sessionStorage.getItem("language")
      ? JSON.parse(sessionStorage.getItem("language"))
      : { id: "1", language: "EN", flag: "en", flagImage: usa }
  );

  //changing language`
  useEffect(() => {
    if (sessionStorage.getItem("language")) {
      const language = JSON.parse(sessionStorage.getItem("language"));
      if (language?.flag === "ar") {
        document.body.dir = "rtl";
      } else {
        document.body.dir = "ltr";
      }
    } else {
      sessionStorage.setItem(
        "language",
        JSON.stringify({ id: "1", language: "EN", flag: "en", flagImage: usa })
      );
      document.body.dir = "ltr";
    }
  }, [selectedLanguage]);

  const selectLanguageOptionHandler = (option) => {
    sessionStorage.setItem("language", JSON.stringify(option));
    setSelectedLanguage(option);
    setShowOptions(false);
    setLocations(false);
    setSelectedLanguage(false);
    window.location.reload(); //page reload
  };

  //home click handler
  const homeHandler = () => {
    navigate("/");
    setMdShow(false);
  };
  //login handler
  const loginHnadler = () => {
    setMdShow(false);
  };
 //logout handler
  const logoutHandler = () => {
    sessionStorage.removeItem("user_id");
    navigate("/");
    setMdShow(false);
  };
   //my account handler
  const myAccHandler = () => {
    setMdShow(false);
    navigate("/myaccount");
  };
  //my bookings handler
  const myBookingHandler = () => {
    setMdShow(false);
    navigate("/mybooking");
  };

  // navigating to cart
  const clickCartHandler = () => {
    setMdShow(false);
    navigate("/cart");
  };

  const cart = useContext(cartContext);
  let cartData = cart.cartItems;

  //item count
  let totalItems = 0;
  for (let i = 1; i <= cartData.length; i++) {
    totalItems = i;
  }
  const [mdShow, setMdShow] = useState(false);
  const ref = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (mdShow && ref.current && !ref.current.contains(e.target)) {
        setMdShow(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [mdShow]);

  return (
    <>
      <div className="header">
        <div className="main">
          <div className="web-logo">
            <div className="logo" onClick={homeHandler}>
              <img src={logo} alt="website logo" />
            </div>
          </div>
          <div className="search-city-fields">
            <div className="md-search-fields">
              <FontAwesomeIcon
                icon={faSearch}
                className={`${
                  selectedLanguage.flag === "ar"
                    ? "ar_md_search_icon"
                    : "md_search_icon"
                }`}
                onClick={handleShowSearch}
              />
            </div>
            <div className="city-fields">
              <div
                className="location-controls"
                id={`${
                  selectedLanguage.flag === "ar"
                    ? "ar_loc-controls"
                    : "loc-controls"
                }`}
              >
                <button
                  name="flag"
                  id="flag"
                  onClick={() => setLocations((prevOption) => !prevOption)}
                >
                  {" "}
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    className="text-color"
                  />
                  {selectedLocation.city_name}
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className={"select-dropdown-icon text-color"}
                  />
                </button>

                {locations && locations && (
                  <div
                    className={`${
                      selectedLanguage.flag === "ar"
                        ? "ar_loc-container"
                        : "loc-container"
                    }`}
                  >
                    <ul className={"options-loc"}>
                      {locationOptions.map((option, i) => (
                        <li
                          className={"dropdown-loc"}
                          key={i}
                          onClick={() => selectLocationOptionHandler(option)}
                        >
                          <p
                            className={`${
                              selectedLanguage.flag === "ar"
                                ? "right_loc-align"
                                : "left_loc-align"
                            }`}
                          >
                            {option.city_name}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="search-filds">
              <div className="search_field">
                <span className=" text-secondary">
                  <FontAwesomeIcon
                    icon={faSearch}
                    className={`${
                      selectedLanguage.flag === "ar"
                        ? "ar_search_icon"
                        : "search_icon"
                    }`}
                  />
                </span>
                <input
                  type="search"
                  placeholder="Search here"
                  className=" form-control search_input"
                  autoComplete="off"
                  value={search}
                  onChange={(e) => getSearchServices(e.target.value)}
                  id={`${
                    selectedLanguage.flag === "ar" ? "ar_right" : "en_left"
                  }`}
                />
                <div className="serched_packages">
                  {search &&
                    searchDetails &&
                    searchDetails.map((data, i) => (
                      <p
                        key={i}
                        onClick={() => clickedSearchedHandler(data)}
                        className="searched_items"
                      >
                        {data.service_title}
                      </p>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="cart-log-lang">
            <div className="cart-field">
              <div
                onClick={clickCartHandler}
                className={`${
                  selectedLanguage.flag === "ar"
                    ? "ar_cart-count"
                    : "cart-count"
                }`}
              >
                {totalItems}
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faCartShopping}
                  onClick={clickCartHandler}
                  className={`${
                    selectedLanguage.flag === "ar" ? "ar_cart" : "en_cart"
                  }`}
                />
              </div>
            </div>
            <div className="login-logout">
              {sessionStorage.getItem("user_id") ? (
                <>
                  <button
                    type="button"
                    className={`${
                      selectedLanguage.flag === "ar"
                        ? "dropdown-toggle bg-light ar_logout"
                        : "dropdown-toggle bg-light logout"
                    }`}
                    id={`${selectedLanguage.flag === "ar" ? "" : ""}`}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <FontAwesomeIcon icon={faUser} className="" />
                  </button>
                  <ul
                    className="dropdown-menu "
                    id="logout_op"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <Link to="myaccount">
                      <li className="dropdown-item ">{changedLang.myAcc}</li>
                    </Link>
                    <Link to="mybooking">
                      {" "}
                      <li className="dropdown-item ">
                        {changedLang.myBooking}
                      </li>
                    </Link>
                    <li onClick={logoutHandler} className="dropdown-item">
                      {changedLang.logOut}
                    </li>
                  </ul>
                </>
              ) : (
                <Button
                  className=" login_btn col"
                  variant="light"
                  onClick={props.checkAuth}
                >
                  Login
                </Button>
              )}
              {sessionStorage.getItem("user_id") ? (
                <button
                  type="button"
                  className={`${
                    selectedLanguage.flag === "ar"
                      ? "dropdown-toggle bg-light ar_md_logout"
                      : "dropdown-toggle bg-light md_logout"
                  }`}
                  id={`${selectedLanguage.flag === "ar" ? "" : ""}`}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon={faUser} className="" />
                </button>
              ) : (
                <FontAwesomeIcon
                  icon={faUser}
                  className={`${
                    selectedLanguage.flag === "ar"
                      ? "ar_md_userLogin"
                      : "md_userLogin"
                  }`}
                  onClick={props.checkAuth}
                />
              )}
            </div>
            <div className="lang">
              <div
                className="form-controls"
                id={`${
                  selectedLanguage.flag === "ar"
                    ? "ar_form-controls"
                    : "form-controls"
                }`}
              >
                <button
                  name="flag"
                  id="flag"
                  onClick={() => setShowOptions((prevOption) => !prevOption)}
                >
                  <img
                    src={selectedLanguage.flagImage}
                    alt="flag"
                    className="flag-image"
                  />
                  <img src={globe} alt="globe" className="globe-image" />
                  {selectedLanguage.language}
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className={"select-dropdown-icon"}
                  />
                </button>
                {showOptions && (
                  <div
                    className={`${
                      selectedLanguage.flag === "ar"
                        ? "ar_options-container"
                        : "options-container"
                    }`}
                  >
                    <ul className={"options-lists"}>
                      {languageOptions?.map((option) => (
                        <li
                          className={"dropdown-list"}
                          key={`${option.flag}_${Math.random.toString()}`}
                          onClick={() => selectLanguageOptionHandler(option)}
                        >
                          <img src={option.flagImage} alt="flag" />
                          <p
                            htmlFor={option.flag}
                            className={`${
                              selectedLanguage.flag === "ar"
                                ? "right-align"
                                : "left-align"
                            }`}
                          >
                            {option.language}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="cart-hamberg">
            <div className="cart-field">
              <div
                className={`${
                  selectedLanguage.flag === "ar"
                    ? "ar_cart-count"
                    : "cart-count"
                }`}
              >
                {totalItems}
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faCartShopping}
                  onClick={clickCartHandler}
                  className={`${
                    selectedLanguage.flag === "ar" ? "ar_cart" : "en_cart"
                  }`}
                />
              </div>
            </div>
            <div onClick={() => setMdShow(!mdShow)} className="hamberg">
              <FontAwesomeIcon icon={faBars} />
            </div>
          </div>
        </div>
      </div>
      {/* medium-small devices view  */}

      {mdShow && (
        <div
          className={mdShow ? "md-device-show" : "md-device-hide"}
          ref={ref}
          id={`${selectedLanguage.flag === "ar" ? "ar_md-modal" : "md-modal"}`}
        >
          <div className="login-logout">
            {sessionStorage.getItem("user_id") ? (
              <div className="mx-1 d-flex flex-column gap-2">
                <div
                  className="d-flex align-items-center gap-2"
                  onClick={myAccHandler}
                >
                  <FontAwesomeIcon icon={faUserAlt} className="text-color" />
                  <span>{changedLang.myAcc}</span>
                </div>
                <div
                  className="d-flex align-items-center gap-2"
                  onClick={myBookingHandler}
                >
                  <FontAwesomeIcon
                    icon={faFolderClosed}
                    className="text-color"
                  />
                  <span>{changedLang.myBooking}</span>
                </div>
              </div>
            ) : (
              <div
                className="mx-2 d-flex gap-2 align-items-center"
                onClick={loginHnadler}
              >
                <FontAwesomeIcon
                  icon={faRightToBracket}
                  className={"select-dropdown-icon text-color"}
                />{" "}
                <span className=" " variant="light" onClick={props.checkAuth}>
                  Login
                </span>
              </div>
            )}
          </div>
          <div className="md-city-fields">
            <div
              className="location-controls"
              id={`${
                selectedLanguage.flag === "ar"
                  ? "ar_loc-controls"
                  : "loc-controls"
              }`}
            >
              <button
                name="flag"
                id="flag"
                onClick={() => setLocations((prevOption) => !prevOption)}
              >
                {" "}
                <FontAwesomeIcon icon={faLocationDot} className="text-color" />
                {selectedLocation.city_name}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={"select-dropdown-icon text-color"}
                />
              </button>

              {locations && locations && (
                <div
                  className={`${
                    selectedLanguage.flag === "ar"
                      ? "md-ar_loc-container"
                      : "md-loc-container"
                  }`}
                >
                  <ul className={"options-loc"}>
                    {locationOptions.map((option, i) => (
                      <li
                        className={"dropdown-loc"}
                        key={i}
                        onClick={() => selectLocationOptionHandler(option)}
                      >
                        <p
                          className={`${
                            selectedLanguage.flag === "ar"
                              ? "right_loc-align"
                              : "left_loc-align"
                          }`}
                        >
                          {option.city_name}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className="lang">
            <div
              className="form-controls"
              id={`${
                selectedLanguage.flag === "ar"
                  ? "ar_form-controls"
                  : "form-controls"
              }`}
            >
              <button
                name="flag"
                id="flag"
                onClick={() => setShowOptions((prevOption) => !prevOption)}
              >
                <img
                  src={selectedLanguage.flagImage}
                  alt="flag"
                  className="flag-image"
                />
                <img src={globe} alt="globe" className="globe-image" />
                {selectedLanguage.language}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={"select-dropdown-icon"}
                />
              </button>
              {showOptions && (
                <div
                  className={`${
                    selectedLanguage.flag === "ar"
                      ? "md-ar_options-container"
                      : "md-options-container"
                  }`}
                >
                  <ul className={"options-lists"}>
                    {languageOptions &&
                      languageOptions.map((option) => (
                        <li
                          className={"dropdown-list"}
                          key={`${option.flag}_${Math.random.toString()}`}
                          onClick={() => selectLanguageOptionHandler(option)}
                        >
                          <img src={option.flagImage} alt="flag" />
                          <p
                            htmlFor={option.flag}
                            className={`${
                              selectedLanguage.flag === "ar"
                                ? "right-align"
                                : "left-align"
                            }`}
                          >
                            {option.language}
                          </p>
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          {sessionStorage.getItem("user_id") && (
            <div
              className="d-flex align-items-center gap-2"
              onClick={logoutHandler}
            >
              <FontAwesomeIcon icon={faRightToBracket} className="text-color" />
              <span> {changedLang.logOut}</span>
            </div>
          )}
        </div>
      )}
      {/* modal for search small devices  */}
      <Modal show={showSearch} onHide={handleCloseSearch} className="md_search">
        <FontAwesomeIcon
          icon={faXmark}
          className={`${
            selectedLanguage.flag === "ar" ? "ar_md_cross" : "md_cross"
          }`}
          onClick={handleCloseSearch}
        />
        <Form>
          <Form.Control
            type="text"
            className="md_search_model"
            placeholder="Search here"
            aria-label="Username"
            aria-describedby="basic-addon1"
            value={search}
            onChange={(e) => getSearchServices(e.target.value)}
          />
        </Form>
        <div className="serched_packages">
          {search &&
            searchDetails &&
            searchDetails.map((data, i) => (
              <p
                key={i}
                onClick={() => clickedSearchedHandler(data)}
                className="searched_items"
              >
                {data.service_title}
              </p>
            ))}
        </div>
      </Modal>
      <Outlet />
    </>
  );
};

export default Header;
