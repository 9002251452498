import React, { useContext, useEffect, useState } from "react";
import { BASE_URL, clientKey, currency } from "../../config/config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import langContext from "../../context/langsContext";
import { languageFunc, changedLang } from "../../config/language";

const MyBookings = () => {
  const navigate = useNavigate();
  const [myBookings, setMyBookings] = useState([]);
  //laguage id
  let languageID = JSON.parse(sessionStorage.getItem("language"));
  languageID = languageID.id;

  //arabic language-english
  languageFunc(useContext(langContext));

  //getting all bookings
  const getMyAllBooking = () => {
    axios
      .post(`${BASE_URL}/getallbookings`, {
        clientKey,
        userID: sessionStorage.getItem("user_id"),
        langID: languageID,
      })
      .then((res) => {
        if (res.data.code === 200) {
          setMyBookings(res.data.data);
        } else {
          return;
        }
      })
      .catch((e) => navigate("/error"));
  };
  useEffect(() => {
    getMyAllBooking();
    window.scrollTo(0, 1);
    // eslint-disable-next-line
  }, []);

  const bookingDetailsHnadler = (booking_id) => {
    navigate(`/booking-details/${booking_id}`);
  };
  return (
    <div className="container allpages">
      <div className="parent">
        <div className="mybookings text-color">
          <div className="booking-head">
            <h5>{changedLang.bookingHead}</h5>
          </div>
          <hr />
          {myBookings?.map((booking, i) => (
            <div key={i} className="card mb-3 p-3 bg-light allbookings ">
              <p>
                {changedLang.status} : {booking?.booking_status}
              </p>
              <h6 className="text-color">
                {changedLang.bookingRef} : {booking?.booking_refnum}
              </h6>
              <p>
                {changedLang.bookingDate}: {booking?.booking_date}
              </p>
              <button
                className="btn btn-primary w-25"
                onClick={() => bookingDetailsHnadler(booking?.booking_id)}
              >
                {changedLang.view}{" "}
              </button>
              <p className="mt-2">
                {changedLang.amountPaid}:{" "}
                <span className="currency">{currency} </span>{" "}
                {booking?.total_amount}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyBookings;
