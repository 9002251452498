import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faUser } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import langContext from "../../context/langsContext";
import { languageFunc, changedLang } from "../../config/language";

const Testimonials = () => {
  //arabic language-english
  languageFunc(useContext(langContext));

  return (
    <div className="container-fluid mt-5 p-5 testimonials-page">
      <div className="p-3">
        <h4 className=" mb-3 text-color">{changedLang.testimonial}</h4>
        <h1 className="subheader">{changedLang.testimonialSubHead}</h1>
      </div>

      <div className="container mt-3">
        <div id="carouselExampleDark" className="carousel carousel-dark slide">
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div>
                <div className="d-block w-100">
                  <div className="d-flex align-items-center gap-5">
                    <div className="tesimonial-user-icon">
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                    <h2>AMARNATH SHETTY FAVWORKS TECHNOLOGIES</h2>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-3 testimonials-para">
                  <div className="tesimonial-quotes-icon">
                    <FontAwesomeIcon icon={faQuoteLeft} />
                  </div>
                  <p>
                    LoremHigh Level Window & Facade Cleaning High Level Window &
                    Facade Cleaning High Level Window & Facade Cleaning The
                    exterior of a building premises is the first thing that
                    clients, suppliers & employees see as they arrive;
                  </p>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div>
                <div className="d-block w-100">
                  <div className="d-flex align-items-center gap-5 ">
                    <div className="tesimonial-user-icon">
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                    <h2>VINAY KUMAR CHAUHAN FAVWORKS TECHNOLOGIES</h2>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-3 testimonials-para">
                  <div className="tesimonial-quotes-icon">
                    <FontAwesomeIcon icon={faQuoteLeft} />
                  </div>
                  <p>
                    LoremHigh Level Window & Facade Cleaning High Level Window &
                    Facade Cleaning High Level Window & Facade Cleaning The
                    exterior of a building premises is the first thing that
                    clients, suppliers & employees see as they arrive;
                  </p>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div>
                <div className="d-block w-100">
                  <div className="d-flex align-items-center gap-5 ">
                    <div className="tesimonial-user-icon">
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                    <h2>SHREYA KHATAVKAR FAVWORKS TECHNOLOGIES</h2>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-3 testimonials-para">
                  <div className="tesimonial-quotes-icon">
                    <FontAwesomeIcon icon={faQuoteLeft} />
                  </div>
                  <p>
                    LoremHigh Level Window & Facade Cleaning High Level Window &
                    Facade Cleaning High Level Window & Facade Cleaning The
                    exterior of a building premises is the first thing that
                    clients, suppliers & employees see as they arrive;
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
