import React, { useEffect } from "react";
import "./Errostyle.css";
const Error = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="hero">
        <div className="text-bg-image">
          <h2>OOPS!</h2>
        </div>
        <h3>Page Not Found (404)..!</h3>
      </div>
    </>
  );
};

export default Error;
