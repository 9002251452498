import {
  faArrowLeft,
  faArrowRight,
  faCalendarAlt,
  faHome,
  faReceipt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL, clientKey, currency } from "../../config/config";
import axios from "axios";
import { useState } from "react";
import langContext from "../../context/langsContext";
import { languageFunc, changedLang } from "../../config/language";

const BookingDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const bookingID = params.id;
  let lang = JSON.parse(sessionStorage.getItem("language"));
  //laguage id
  let languageID = lang.id;
  //arabic language-english
  languageFunc(useContext(langContext));

  const [bookingDetails, setBookingDetails] = useState([]);

  const [packages, setPackages] = useState([]);
  //get booking details
  const getBookingDetails = () => {
    axios
      .post(`${BASE_URL}/getbookingdetails`, {
        clientKey,
        userID: sessionStorage.getItem("user_id"),
        bookingID,
        langID: languageID,
      })
      .then((res) => {
        if (res.data.code === 200) {
          setBookingDetails(res.data.data);
          setPackages(res.data.data.packages);
        } else {
          return;
        }
      })
      .catch((e) => navigate("/error"));
  };
  const backClickHandler = () => {
    // navigate("/mybooking");
    window.history.go(-1);
  };
  const helpHandler = () => {
    navigate("/help");
  };
  useEffect(() => {
    getBookingDetails();
    window.scrollTo(0, 1);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container allpages">
      <div className="parent">
        <div className="mybookings">
          <div className="packege">
            <div className="booking_head">
              <div className="arr_head text-color">
                <h4 className="back_btn" onClick={backClickHandler}>
                  {lang.flag === "en" ? (
                    <FontAwesomeIcon icon={faArrowLeft} />
                  ) : (
                    <FontAwesomeIcon icon={faArrowRight} />
                  )}
                </h4>
                <h4>{packages && packages.package_title} </h4>
              </div>
              <button className="btn btn-light help" onClick={helpHandler}>
                {changedLang.help}
              </button>
            </div>
            <div className="ref_num">
              <p>
                {changedLang.refNo}: {bookingDetails?.booking_refnum}
              </p>
              <p>
                {" "}
                {changedLang.date}: {bookingDetails?.booking_date}
              </p>
            </div>
          </div>
          <hr />
          <div className="bookings_details ">
            <div className="bookings card p-3 mb-3 bg-light ">
              <h5 className="text-color">{changedLang.booking_details}</h5>
              <hr style={{ borderTop: "dotted 1px" }} />
              <div className="booking_header text-color">
                <h6>{changedLang.packageTitle}</h6>

                <h6>{changedLang.amount}</h6>
              </div>
              {packages?.map((data, i) => (
                <div key={i} className="booking_package text-color">
                  <p>
                    {data.package_title}{" "}
                    {data.cost_type === 1 ? (
                      <span className="small-text">/Package</span>
                    ) : (
                      <span className="small-text">/Hr</span>
                    )}
                  </p>

                  <p>
                    <span className="currency">{currency} </span> {data.price}
                  </p>
                </div>
              ))}
              <hr style={{ borderTop: "dotted 1px" }} />
              <div className="add">
                <h6 className="text-color">
                  <FontAwesomeIcon icon={faReceipt} className="text-color" />{" "}
                  {changedLang.totalVat}:
                </h6>
                <p>
                  <span>{bookingDetails?.total_vat}</span>
                </p>
              </div>
              <hr style={{ borderTop: "dotted 1px" }} />
              <div className="add">
                <h6 className="text-color">
                  <FontAwesomeIcon icon={faReceipt} className="text-color" />{" "}
                  {changedLang.amountPaid}:
                </h6>
                <p>
                  <span>
                    <span className="currency">{currency} </span>{" "}
                    {bookingDetails?.total_amount}
                  </span>
                </p>
              </div>
              <hr style={{ borderTop: "dotted 1px" }} />
              <div className="add">
                <div>
                  <FontAwesomeIcon icon={faHome} className="text-color" />
                </div>
                <div>
                  <span className="add_title">
                    {bookingDetails?.address_title}
                  </span>
                  : {bookingDetails?.address_1} {bookingDetails?.address_2}{" "}
                  {bookingDetails?.location} {bookingDetails?.city_name}{" "}
                  {bookingDetails?.zip_code}
                </div>
              </div>
              <hr style={{ borderTop: "dotted 1px" }} />
              <div className="service_date">
                <FontAwesomeIcon icon={faCalendarAlt} className="text-color" />
                <span>
                  {bookingDetails?.service_date} at{" "}
                  {bookingDetails?.service_time}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;
