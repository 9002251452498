// mobile number validation
export const validateMobile = (mobile, errField) => {
  const mobileRegex = /^[0-9]+$/;
  let obj = document.getElementById(mobile);
  if (
    obj.value === "" ||
    obj.value.length < 9 ||
    obj.value.length > 10 ||
    !mobileRegex.test(obj.value)
  ) {
    obj.setAttribute("class", "mobnum form-control input-error");
    document.getElementById(errField).style = "display:block;";
    return false;
  } else {
    obj.setAttribute("class", "mobnum form-control");
    document.getElementById(errField).style = "display:none;";
    return true;
  }
};

//email id validation=
export const validateEmail = (email, errField) => {
  const emailRegex = /\S+@\S+\.\S+/;
  let obj = document.getElementById(email);
  if (obj.value === "" || !emailRegex.test(obj.value)) {
    obj.setAttribute("class", "email  form-control input-error");
    document.getElementById(errField).style = "display:block;";
    return false;
  } else {
    obj.setAttribute("class", "email form-control");
    document.getElementById(errField).style = "display:none;";
    return true;
  }
};

// check for blank
export const checkForBlank = (input, errField) => {
  let obj = document.getElementById(input);
  if (obj.value === "") {
    obj.setAttribute("class", "input form-control input-error");
    document.getElementById(errField).style = "display:block;";
    return false;
  } else {
    obj.setAttribute("class", "input form-control");
    document.getElementById(errField).style = "display:none;";
    return true;
  }
};
